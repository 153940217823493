module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rebecca Frey Soul Coding","short_name":"Rebecca Frey","description":"Rebecca Frey, Web and Mobile Developer from Hamburg","start_url":"/","background_color":"#e6ac00","theme_color":"#e6ac00","display":"minimal-ui","icon":"static/assets/semikolon_round.png","icons":[{"src":"/assets/maskable_icon.png","sizes":"196x196","type":"image/png","purpose":"any maskable"},{"src":"/assets/semikolon_round.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7a2bdec17536122f666b6022565b8db0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
